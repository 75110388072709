import React, { useState } from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { BrandCard } from "../components/brand-card";
import SectionTitle from "../components/section-title";
import { slugify } from "../utils";

const GOOGLECONF = {
  apikey: "AIzaSyDZJGoYyXFrpxhDGrG-mZm5dZm1JVEcsT8",
  cx: "af5caaf916a8c8ac2",
};

const IndexPage = ({ data }) => {
  const [results, setResults] = useState(null);
  const [query, setQuery] = useState("");
  const onChange = (e) => {
    setQuery(e.target.value);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    fetch(
      `https://customsearch.googleapis.com/customsearch/v1?q=${encodeURIComponent(
        query
      )}&key=${GOOGLECONF.apikey}&cx=${GOOGLECONF.cx}`
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data.items);
        setResults(data?.items ?? []);
      });
  };

  return (
    <Layout>
      <SEO title={"Brand Scores"} desc={"Search scores on Choose Because"} />
      <div className="max-w-screen-xl md:mx-auto p-2 md:p-8">
        <section className="flex mt-0 justify-center md:justify-between items-center flex-wrap gap-8 rounded-xl p-4 md:p-8 shadow-md bg-white">
          <form onSubmit={onSubmit} className="flex gap-4 flex-wrap">
            <input
              value={query}
              onChange={onChange}
              style={{ width: "478px", maxWidth: "90vw" }}
              className="focus:border-bc-sky focus:ring-bc-sky"
              type="text"
              placeholder="e.g. ATT, Walmart, women's rights..."
            />
            <button
              onClick={onSubmit}
              className="bg-bc-sky hover:bg-bc-blue transition-colors text-white font-bold py-2 px-4 rounded"
            >
              Search
            </button>
          </form>

          <div className="flex flex-col gap-4 bg-white w-full">
            {results &&
              results.map((result) => (
                <a href={result.link} key={result.cacheId}>
                  <div className="bg-bc-sky transition-all transform hover:-translate-y-1 rounded-md shadow-md hover:shadow-lg p-4 my-2">
                    <h2
                      className="text-xl font-bold leading-tight"
                      dangerouslySetInnerHTML={{ __html: result.htmlTitle }}
                    />
                    <div
                      className="mt-2 text-small font-light"
                      dangerouslySetInnerHTML={{ __html: result.htmlSnippet }}
                    />
                  </div>
                </a>
              ))}
            {results && results.length === 0 && (
              <div className="text-xl font-bold my-2 w-full">
                We did not find any brand matching your query :(
              </div>
            )}
          </div>
        </section>

        <section>
          <SectionTitle>Example Scores</SectionTitle>
          <div className="flex flex-col gap-8 mt-4">
            {data?.postgres.allScoresHighlightedscores.edges.map((edge) => (
              <Link
                key={edge.node.id}
                to={`/${slugify(
                  edge.node.scoresScoreByScoreId.scoresBrandByBrandId.name
                )}/${slugify(
                  edge.node.scoresScoreByScoreId.scoresCauseByCauseId.name
                )}/`}
              >
                <BrandCard
                  className="rounded-xl p-4 shadow-md bg-white transition-all transform hover:-translate-y-1 hover:shadow-lg"
                  score={edge.node.scoresScoreByScoreId}
                  brand={edge.node.scoresScoreByScoreId.scoresBrandByBrandId}
                  cause={edge.node.scoresScoreByScoreId.scoresCauseByCauseId}
                />
              </Link>
            ))}
          </div>
        </section>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query HighlightedScoresQuery {
    postgres {
      allScoresHighlightedscores {
        edges {
          node {
            id
            scoresScoreByScoreId {
              value
              summary
              scoresCauseByCauseId {
                name
              }
              scoresBrandByBrandId {
                name
                logo
                inheritsScoresFromId
                scoresBrandByInheritsScoresFromId {
                  name
                  logo
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
